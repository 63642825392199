<template>
    <div class="drop_zone center" :class="{'drag_hover' : drop_zone_drag_over}" @click.stop="$refs.file_input.click()" @dragover="drop_zone_drag_over = true" @dragleave="drop_zone_drag_over = false" @drop="handleDrop($event)">
        <div class="drop_zone_image">
            <img src="../../../../assets/upload_file_blue.png" />
            <img src="../../../../assets/upload_file_light_blue.png" />
        </div>
        <p v-if="upload_progression != undefined">{{ upload_progression }}%</p>
        <p v-else>{{$t('overlays.upload_your_media_here')}}</p>

        <input ref="file_input" style="display: none;" type="file" accept="video/*, image/*" @change="handleInputFile" />

    </div>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted, defineEmits } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const drop_zone_drag_over = ref(false);
const upload_progression = ref();

import * as VueI18n from "vue-i18n"
const { t } = VueI18n.useI18n();

const emit = defineEmits(['needRefreshList'])

function handleDrop(evt){
    if (evt.dataTransfer && evt.dataTransfer.files[0]) {
        handelFile(evt.dataTransfer.files[0])
    }
};

function handleInputFile(evt){
    handelFile(evt.target.files[0])
}

onMounted(()=>{
    //console.log(upload_progression.value)
})

async function handelFile(file){
    try {
        if (/video|image/.test(file.type) == false) {
          throw "E0403";
        }

        let formData = new FormData()
        formData.append('file', file)
        formData.append('usr_id', store.getters.getCurrentUsrId)
        formData.append('media_type', "overlay")

        if (file.size / 1024 / 1024 / 1024 > 5) {
          throw "E0401";
        }

        //UPLOAD
        await axios.post('/medias/', formData,
          {
            //REPORT UPLOAD PROGRESS
            onUploadProgress: (progressEvent => {
              upload_progression.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            })
          })
          .then(async (res) => {
            upload_progression.value = undefined
            if (res.data && res.data.media_id) {
              this.media_id = res.data.media_id
            }

            emit('needRefreshList')
          })
          .catch((err) => {
            throw err
          })
        
        upload_progression.value = undefined

      } catch (err) {
        console.error(err?.response?.data || err)
        if (err == "E0401" || err?.response?.data?.errorCode == "E0401") {
          this.Toaster.warn(this.$t("CatalogPanel.error.mediaTooHeavy"));
        } else if (err?.response?.data?.errorCode == "E0402") {
          this.Toaster.warn(this.$t("CatalogPanel.error.mediaTooLong", { time: this.maximal_time }));
        } else if (err == "E0403" || err?.response?.data?.errorCode == "E0403") {
          this.Toaster.warn(this.$t("CatalogPanel.error.videoFormatRequired"));
        } else if (err?.response?.data?.code == "notEnoughCredit" || err?.response?.data?.code == "missingCredit") {
          this.Toaster.prompt({
            title: this.$t('CatalogPanel.error.notEnoughCredit'),
            message: this.$t('CatalogPanel.error.doYouWantToUpgrade'),
            validate: this.$t('upgrade'),
            cancel: this.$t('no'),
          }).then((response) => {
            if (response == "validate") {
              this.$router.push('/profile/subscriptions')
            }
          })
        }

        if (this.media_id != undefined) {
          this.upload_mode = false
        } else {
          
        }
      }
}





</script>


<style lang="scss" scopped>
.drop_zone{
    position: relative;
    width: 90%;
    height: 130px;
    border: 2px solid rgba(128, 128, 128, 0.547);
    border: 3px dashed grey;
    border-radius: var(--border_radius);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;

    & > p{
        margin-top: 10px;
        font-weight: 500;
    }

    & > .drop_zone_image {
        height: 40px;
        width: 40px;
    }
    
    & > .drop_zone_image > img{
        position: absolute;
        height: 40px;
    }

    & > .drop_zone_image > img:nth-of-type(2) {
        opacity: 0;
    }

    &.drag_hover {
        border: dashed var(--color1) 3px;

        & > .drop_zone_image > img:nth-of-type(1) {
            opacity: 0;
        }

        & > .drop_zone_image > img:nth-of-type(2) {
            opacity: 1;
        }
    
        & > p {
            color: var(--color1);
        }
    }
}


</style>